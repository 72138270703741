<template>
  <div class="card">
    <template v-if="pdf">
      <div v-if="$slots.headerContext" class="card-body">
        <slot name="headerContext" />
      </div>

      <template v-if="pages.length">
        <slot name="page" :component="$options.components.PdfPage" :data="pages[currentPage - 1]">
          <pdf-page :page="pages[currentPage - 1]" />
        </slot>
      </template>
    </template>
    <div v-else-if="loading" class="card-body">
      <div class="my-7 mx-7">
        <h6 class="text-center text-muted">Loading Document</h6>
        <el-progress
          :percentage="percentLoaded"
          :status="percentLoaded === 100 ? 'success' : undefined"
          :show-text="false"
        />
      </div>
    </div>
    <div v-else class="card-body">
      <el-button icon="el-icon-refresh" />
    </div>
  </div>
</template>

<script type="text/javascript">
import FileSaver from 'file-saver';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import PdfPage from '@/modules/core/components/PdfViewer/PdfPage.vue';

export default {
  name: 'PdfViewer',
  components: { PdfPage },
  props: {
    src: {
      type: [String, ArrayBuffer],
      required: true,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      percentLoaded: 0,
      pdf: undefined,
      currentPage: this.page,
      pages: [],
    };
  },
  computed: {
    loading() {
      return this.loadingTask !== undefined;
    },
  },
  watch: {
    src: 'fetchPDF',
    currentPage(value) {
      this.$emit('update:page', value);
    },
    page(value) {
      this.currentPage = value;
    },
  },
  created() {
    this.fetchPDF();
  },
  beforeDestroy() {
    this.cleanup();
  },
  methods: {
    fetchPDF() {
      this.cleanup();
      this.loadingTask = pdfjs.getDocument(this.src);

      // setup loading callback
      this.loadingTask.onProgress = ({ loaded, total }) => {
        if (!total) return;
        const ratio = Math.min(loaded / total, 1);
        this.percentLoaded = Math.floor(ratio * 100);
      };

      const taskId = this.loadingTask.docId;
      this.loadingTask.promise
        .then((pdf) => {
          this.pdf = pdf;
          const getPagePromises = [...Array(pdf.numPages).keys()].map((i) => pdf.getPage(i + 1));
          return Promise.all(getPagePromises);
        })
        .then((pages) => {
          this.pages = pages;
        })
        .catch((err) => {
          // disregard error if comming from previous task
          if (this.loadingTask === undefined || this.loadingTask.docId !== taskId) return;

          this.loadingTask.destroy();
          this.loadingTask = undefined;
          // TODO: better handle error
          console.error(err);
        });
    },
    cleanup() {
      if (this.loadingTask) {
        this.loadingTask = undefined;
      }
      if (this.pdf) {
        this.pdf.cleanup();
        this.pdf = undefined;
      }
      this.pages.forEach((page) => page.cleanup());
      this.pages = [];
      this.percentLoaded = 0;
      this.currentPage = 1;
    },
    downloadPDF() {
      this.pdf.getMetadata().then(({ contentDispositionFilename }) => {
        this.pdf
          .getData()
          .then((data) => {
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, contentDispositionFilename || 'document.pdf');
          })
          .catch((err) => {
            this.$message.error(`${err.error} - ${err.reason}`);
          });
      });
    },
  },
};
</script>
