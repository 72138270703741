<template>
  <div class="pt-2">
    <h3 v-if="!loading" class="mb-4">
      {{ $t('document.documentsOverview.recordingTasks.title') }}
    </h3>

    <TableLoadingSkeleton v-if="loading" />
    <Table
      v-if="!loading"
      :columns="columns"
      :data="tasksData"
      show-index
      rounded
      border
      :hover="isManageUser"
      @row-click="handleRowClick"
    >
      <template #cell-managerReviewRequired="{ rowData: { managerReviewRequired } }">
        <span v-if="managerReviewRequired" class="review-required-badge text-primary badge p-2">
          {{ $t('document.documentRecording.managerReviewRequired') }}
        </span>
        <span v-else />
      </template>
    </Table>
  </div>
</template>

<script>
import { computed } from 'vue';

import { useDocumentTasks } from '@/modules/document/compositions';
import { Table, TableLoadingSkeleton } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { useUserPermissions } from '@/modules/auth/compositions';
import { USER_PERMISSIONS_LIST } from '@/permissions';
import { options } from '@/locale/dateConfig';

import { useDocuments, useCurrency } from '@/modules/document/compositions';

const TABLE_HEADERS = {
  UPLOADED_AT: 'uploadedAt',
  ISSUE_DATE: 'issueDate',
  SUPPLIER_NAME: 'supplierName',
  DOCUMENT_TYPE: 'documentType',
  DOCUMENT_NUMBER: 'documentNumber',
  TOTAL_AMOUNT: 'totalAmount',
  DETAILS: 'managerReviewRequired',
};

export default {
  components: { Table, TableLoadingSkeleton },
  setup() {
    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const isManageUser = isUserPermittedForActiveTenant(USER_PERMISSIONS_LIST['document.manage']);

    const { currentTenant } = useTenancy();
    const { documentTasks, loading: tasksLoading } = useDocumentTasks(() => ({ businessId: currentTenant.value.id }));
    const recordTasks = computed(() =>
      documentTasks.value
        .filter((task) => task.type === 'record')
        .sort((taskA, taskB) => taskA.createdAt - taskB.createdAt)
    );

    const { currencyLocaleStyle } = useCurrency();

    const { documents, loading: documentsLoading } = useDocuments(
      computed(() => ({
        businessId: currentTenant.value.id,
        recorded: false,
      }))
    );

    return {
      documents,
      isManageUser,
      loading: computed(() => tasksLoading.value || documentsLoading.value),
      recordTasks,
      currencyLocaleStyle,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.issueDate'),
          key: TABLE_HEADERS.ISSUE_DATE,
        },
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.supplierName'),
          key: TABLE_HEADERS.SUPPLIER_NAME,
        },
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.documentType'),
          key: TABLE_HEADERS.DOCUMENT_TYPE,
        },
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.documentNumber'),
          key: TABLE_HEADERS.DOCUMENT_NUMBER,
        },
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
        {
          header: this.$t('document.documentsOverview.recordingTasks.header.details'),
          key: TABLE_HEADERS.DETAILS,
        },
      ];
    },
    tasksData() {
      return !this.loading
        ? this.recordTasks.map(({ id: taskId, data, reviewRequired }) => {
            const document = this.documents.find(({ id }) => id === data.documentId) ?? {};

            return {
              id: taskId,
              uploadedAt: this.formatDate(document.createdAt),
              issueDate: this.formatDateUTC(document.issueDate),
              supplierName: document.supplier?.name ?? '-',
              documentType: document.type ? this.$t(`document.exports.schema.type.shortName.${document.type}`) : '-',
              documentNumber: document.documentNumber ?? '-',
              totalAmount: this.formatMoney(document.totalAmount),
              managerReviewRequired: reviewRequired,
            };
          })
        : [];
    },
  },
  methods: {
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    formatDateUTC(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, { ...options.short, timeZone: 'UTC' }) : '-';
    },
    formatMoney(value) {
      return typeof value === 'number' && !Number.isNaN(value)
        ? Number(value).toLocaleString(this.$i18n.locale, { ...this.currencyLocaleStyle, maximumFractionDigits: 5 })
        : '-';
    },
    handleRowClick(rowIndex) {
      if (this.isManageUser) this.$router.push({ name: 'recordDocuments', query: { taskNumber: rowIndex + 1 } });
    },
  },
};
</script>
<style lang="scss" scoped>
.review-required-badge {
  background: #f5f7fd;
}
</style>
