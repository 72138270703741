<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h3>
        {{ $t('document.documentsOverview.unRecordedDocuments.title') }}
      </h3>
    </div>
    <div class="d-flex align-items-center gap-2 mb-4 table-filters">
      <span class="filter-item">
        <SearchableDropdown
          :selected-item="selectedRestaurant"
          :options="restaurants"
          :label="$t('document.documentsOperationOverview.openTasksTable.filters.restaurant')"
          :search-placeholder="$t('commons.searchWithDots')"
          @change="onRestaurantFilterChange"
        />
      </span>
      <span class="filter-item">
        <CheckableDropdown
          :options="taskTypes"
          :label="$t('document.documentsOperationOverview.openTasksTable.filters.task')"
          @change="onTaskTypeChange"
        />
      </span>
      <div>
        <TableFilter
          :filter-name="$t('document.documentsOperationOverview.openTasksTable.filters.managerReview')"
          button-filter
          :active="showOnlyManagerReview"
          @click="onShowRequiredReviewManagerToggle"
        />
      </div>
    </div>
    <TableLoadingSkeleton v-if="tasksLoading" />
    <Table
      v-else
      :columns="columns"
      :data="tableData"
      custom-class="open-tasks-table"
      show-index
      border
      rounded
      hover
      @row-click="handleRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #cell-createdBefore="{ rowData: { createdBefore } }">
        <TruncatedText>
          {{ createdBefore }}
        </TruncatedText>
      </template>
      <template #cell-businessName="{ rowData: { businessName } }">
        <TruncatedText>
          {{ businessName }}
        </TruncatedText>
      </template>
      <template #cell-taskType="{ rowData: { taskType } }">
        <TruncatedText>
          {{ taskType }}
        </TruncatedText>
      </template>
      <template #cell-managerComment="{ rowData: { managerComment } }">
        <TruncatedText>
          {{ managerComment }}
        </TruncatedText>
      </template>
      <template #cell-managerReviewFlag="{ rowData: { managerReviewFlag } }">
        <FlagIcon v-if="managerReviewFlag" width="18" height="18" />
        <div v-else></div>
      </template>
    </Table>
    <div class="d-flex justify-content-end my-4">
      <el-pagination
        v-if="openTasks.length"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="pageLimit"
        :page-count="Math.ceil(tasksTotalCount / pageLimit)"
        :total="tasksTotalCount"
      />
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from 'vue';
import { DateTime } from 'luxon';
import { Table, TruncatedText, TableFilter, TableLoadingSkeleton } from '@/modules/core';
import { SearchableDropdown, CheckableDropdown } from './table-filters';
import { useDocumentTasks } from '@/modules/document/compositions';
import { FlagIcon } from '@/assets/icons';
import { DOCUMENT_TASK_TYPE } from '../types';

const TABLE_HEADERS = {
  BUSINESS_NAME: 'businessName',
  TASK_TYPE: 'taskType',
  CREATED_BEFORE: 'createdBefore',
  MANAGER_COMMENT: 'managerComment',
  MANAGER_REVIEW_FLAG: 'managerReviewFlag',
};

export default {
  components: {
    Table,
    TableLoadingSkeleton,
    TableFilter,
    SearchableDropdown,
    CheckableDropdown,
    TruncatedText,
    FlagIcon,
  },
  props: {
    restaurants: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { $i18n } = getCurrentInstance().proxy;
    const pageLimit = 50;
    const currentPage = ref(1);

    const pagination = computed(() => ({
      limit: pageLimit,
      offset: (currentPage.value - 1) * pageLimit,
    }));

    const selectedRestaurant = ref(null);
    const selectedTaskTypes = ref(Object.values(DOCUMENT_TASK_TYPE));
    const restPopoverSettings = ref(null);
    const taskPopoverSettings = ref(null);
    const showOnlyManagerReview = ref(false);

    const {
      documentTasks: openTasks,
      totalCount: tasksTotalCount,
      loading: tasksLoading,
    } = useDocumentTasks(
      () => ({
        businessId: selectedRestaurant.value?.id || undefined,
        reviewRequired: showOnlyManagerReview.value || undefined,
        types: selectedTaskTypes.value?.length ? selectedTaskTypes.value : undefined,
        limit: pagination.value.limit,
        offset: pagination.value.offset,
      }),
      {
        // fix loading state when changing pages
        avoidUsingLoading: true,
      }
    );

    const closePopover = () => {
      if (restPopoverSettings.value) {
        restPopoverSettings.value.closePopover();
      }
      if (taskPopoverSettings.value) {
        taskPopoverSettings.value.closePopover();
      }
    };

    const taskTypes = Object.keys(DOCUMENT_TASK_TYPE).map((key) => {
      return {
        id: DOCUMENT_TASK_TYPE[key],
        name: $i18n.t(`document.documentsOperationOverview.openTasksTable.taskTypes.${DOCUMENT_TASK_TYPE[key]}`),
        isPermitted: true,
      };
    });
    return {
      openTasks,
      pageLimit,
      currentPage,
      tasksTotalCount,
      tasksLoading,
      selectedRestaurant,
      selectedTaskTypes,
      restPopoverSettings,
      taskPopoverSettings,
      showOnlyManagerReview,
      closePopover,
      taskTypes,
    };
  },
  computed: {
    columns() {
      return Object.keys(TABLE_HEADERS).map((key) => {
        let columnWidth;

        switch (TABLE_HEADERS[key]) {
          case TABLE_HEADERS.MANAGER_COMMENT:
            columnWidth = '18.725rem';
            break;
          case TABLE_HEADERS.MANAGER_REVIEW_FLAG:
            columnWidth = '1rem';
            break;
          default:
            columnWidth = '9.325rem';
        }

        return Object({
          header:
            TABLE_HEADERS[key] === TABLE_HEADERS.MANAGER_REVIEW_FLAG
              ? ''
              : this.$t(`document.documentsOperationOverview.openTasksTable.header.${TABLE_HEADERS[key]}`),
          key: TABLE_HEADERS[key],
          width: columnWidth,
        });
      });
    },
    tableData() {
      const mappedTasks = this.openTasks.map((task) => {
        return {
          [TABLE_HEADERS.BUSINESS_NAME]: this.getRestaurantName(task.businessId) ?? '-',
          [TABLE_HEADERS.TASK_TYPE]: this.$t(
            `document.documentsOperationOverview.openTasksTable.taskTypes.${task.type}`
          ),
          [TABLE_HEADERS.CREATED_BEFORE]: this.formatDuration(task.createdAt),
          [TABLE_HEADERS.MANAGER_COMMENT]: this.getManagerComment(task) ?? '-',
          [TABLE_HEADERS.MANAGER_REVIEW_FLAG]: this.getManagerReviewFlagState(task),
          type: task.type,
          businessId: task.businessId,
        };
      });
      return mappedTasks;
    },
    taskFilterName() {
      return this.selectedTaskTypes?.optionId ? this.getTaskName(this.selectedTaskTypes?.optionId) : '';
    },
  },
  methods: {
    getRestaurantName(businessId) {
      const restaurant = this.restaurants?.find((r) => r.id === businessId);
      return restaurant?.name;
    },
    getTaskName(taskId) {
      const task = this.taskTypes?.find((t) => t.id === taskId);
      return task.name;
    },
    formatDuration(ms) {
      return DateTime.fromMillis(ms).toRelative({ locale: this.$i18n.locale });
    },
    handleRowClick(rowIndex) {
      let routeName;
      switch (this.tableData[rowIndex].type) {
        case DOCUMENT_TASK_TYPE.organize:
          routeName = 'organizeDocuments';
          break;
        case DOCUMENT_TASK_TYPE.classify:
          routeName = 'classifyDocuments';
          break;
        case DOCUMENT_TASK_TYPE.extract:
          routeName = 'recordDocuments';
          break;
        default:
          routeName = 'documents';
          break;
      }
      this.$router.push({ name: routeName, params: { tenantId: this.tableData[rowIndex].businessId } });
    },
    getManagerComment(task) {
      return task.data.comment;
    },
    getManagerReviewFlagState(task) {
      return !!task.reviewRequired;
    },
    onRestaurantFilterChange(restaurant) {
      this.closePopover();
      this.selectedRestaurant = restaurant;
      this.currentPage = 1;
    },
    onShowRequiredReviewManagerToggle() {
      this.showOnlyManagerReview = !this.showOnlyManagerReview;
      this.currentPage = 1;
    },
    onTaskTypeChange(selected) {
      const ids = selected.map((item) => item.id);
      this.selectedTaskTypes = !ids.length ? Object.values(DOCUMENT_TASK_TYPE) : ids;
      this.currentPage = 1;
    },
    onChangedDocumentType(selected) {
      const ids = selected.map((item) => item.id);
      this.documentTypesFilter = ids;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep .el-popover {
  margin-top: 0.5px !important;
}
</style>
